.navbar {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: center;
  padding: 0.5rem;
  background-color: #fdfdfd;
  position: relative;
  margin-bottom: 0px;
  z-index: 1000;
  min-height: 50px;
}

.navbar-menu {
  flex: 1;
  display: flex;
  justify-content: flex-start;
  list-style-type: none;
  margin: 0;
  padding: 0;
  transition: all 0.3s ease-in-out;
  z-index: 1001;
}

.navbar-menu li {
  margin: 0;
  display: block;
  align-items: baseline;
}

.navbar-menu a {
  text-decoration: none;
  color: #333;
  padding: 0.5rem 0.5rem;
  border-radius: 4px;
  transition: background-color 0.3s ease;
  white-space: nowrap;
}

.navbar-menu a:hover {
  background-color: #f0f0f0;
}

.navbar-logo-container {
  flex: 1 0 100%;
  text-align: center;
  margin: 0 0 0.5rem;
  z-index: 1002;
}

.navbar-logo {
  height: 35px;
}

.navbar button.hamburger-menu {
  display: none;
  background: none;
  border: none;
  cursor: pointer;
  padding: 0;
  width: 20px;
  height: 14px;
  position: relative;
  margin: 0;
  z-index: 1003;
}

.navbar button.hamburger-menu:hover {
  background-color: transparent;
}

.navbar button.hamburger-menu:focus {
  outline: 2px solid #007bff;
  outline-offset: 2px;
}

.navbar button.hamburger-menu span {
  display: block;
  position: absolute;
  height: 2px;
  width: 100%;
  background: #333;
  border-radius: 2px;
  opacity: 1;
  left: 0;
  transform: rotate(0deg);
  transition: .25s ease-in-out;
}

.navbar button.hamburger-menu span:nth-child(1) {
  top: 0px;
}

.navbar button.hamburger-menu span:nth-child(2) {
  top: 7px;
}

.navbar button.hamburger-menu span:nth-child(3) {
  top: 14px;
}

.navbar button.hamburger-menu.open span:nth-child(1) {
  top: 6px;
  transform: rotate(135deg);
}

.navbar button.hamburger-menu.open span:nth-child(2) {
  opacity: 0;
  left: -60px;
}

.navbar button.hamburger-menu.open span:nth-child(3) {
  top: 6px;
  transform: rotate(-135deg);
}

.dropdown {
  position: relative;
  display: inline-block;
  padding: 0;
  margin: 0;
}


.dropdown-menu {
  display: none;
  position: absolute;
  top: 100%;
  left: 0;
  background-color: #f9f9f9;
  min-width: 160px;
  box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
  z-index: 1005;
  padding: 0;
  list-style-type: none;
  border-radius: 4px;
  transition: all 0.3s ease-in-out;
  margin-top: 0;
}

.dropdown.active .dropdown-menu {
  display: block;
}

.dropdown-menu li {
  margin: 0;
  padding: 0;
}

.dropdown-menu a {
  display: block;
  padding: 0.5rem;
  transition: background-color 0.3s ease;
}

.dropdown-menu a:hover {
  background-color: #f0f0f0;
}

.navbar a {
  color: #333;
  text-decoration: none;
}

.profile-picture {
  width: 30px;
  height: 30px;
  border-radius: 50%;
  margin-left: 10px;
}

.profile-dropdown-container {
  position: absolute;
  right: 5px;
  padding-right: 30px;
}

.profile-dropdown-menu {
  display: none;
  position: absolute;
  top: 100%;
  right: 0;
  background-color: #f9f9f9;
  min-width: 160px;
  box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
  z-index: 1003;
  padding: 0.25rem 0;
  list-style-type: none;
  border-radius: 4px;
  transition: all 0.3s ease-in-out;
  margin-top: 0.25rem;
}

.profile-dropdown-menu li:last-child {
  padding-bottom: 0.5rem;
}

.profile-dropdown.active .profile-dropdown-menu {
  display: block;
}

.profile-dropdown-menu.active {
  display: block;
}

.profile-dropdown-menu li {
  padding: 0;
  margin: 0;
}

.profile-dropdown-menu a,
.profile-dropdown-menu button {
  display: flex;
  align-items: center;
  padding: 0.25rem 0.5rem;
  width: 100%;
  text-align: left;
  background: none;
  border: none;
  cursor: pointer;
  color: #333;
  text-decoration: none;
  transition: background-color 0.3s ease;
}

.profile-dropdown-menu a:hover,
.profile-dropdown-menu button:hover {
  background-color: #f0f0f0;
}

.profile-dropdown-menu svg {
  margin-right: 0.5rem;
}

.profile-dropdown-arrow {
  position: absolute;
  right: 10px;
  top: 50%;
  transform: translateY(-50%);
  font-size: 12px;
  color: #333;
  transition: transform 0.3s ease-in-out;
}

.profile-dropdown-arrow.up::after {
  content: '▲'; /* Up arrow */
}

.profile-dropdown-arrow.down::after {
  content: '▼'; /* Down arrow */
}

@media (min-width: 769px) {
  .navbar {
    flex-wrap: nowrap;
    justify-content: flex-start;
  }

  .navbar button.hamburger-menu {
    display: none;
  }

  .dropdown:hover .dropdown-menu,
  .dropdown.active .dropdown-menu {
    display: block;
    animation: fadeIn 0.3s ease-in-out;
  }

  @keyframes fadeIn {
    from {
      opacity: 0;
      transform: translateY(-10px);
    }
    to {
      opacity: 1;
      transform: translateY(0);
    }
  }

  .navbar-menu {
    flex: 0 1 auto;
    justify-content: flex-start;
  }

  .navbar-logo-container {
    flex: 0 1 auto;
    margin: 0 1rem;
    order: -1;
  }

  .dropdown-menu {
    left: auto;
    right: 0;
    padding-top: 0.25rem;
    margin-top: 0.5rem;
  }

  .profile-dropdown {
    display: flex;
    align-items: center;
    cursor: pointer;
  }

  .profile-dropdown .profile-picture {
    margin-left: 0;
  }

  .profile-dropdown .profile-dropdown-menu {
    right: 0;
  }

  .profile-dropdown:hover .profile-dropdown-menu,
  .profile-dropdown.active .profile-dropdown-menu {
    display: block;
    animation: fadeIn 0.3s ease-in-out;
  }
}

@media (max-width: 1024px) and (min-width: 769px) {
  .navbar-menu li {
    margin: 0 0.25rem;
  }
}

@media (max-width: 768px) {
  .navbar {
    flex-direction: column;
    align-items: stretch;
    padding-top: 0.25rem;
    padding-bottom: 0.25rem;
  }

  .navbar button.hamburger-menu {
    display: block;
    position: absolute;
    top: 1rem;
    left: 0.75rem;
    z-index: 1003;
  }

  .navbar-menu {
    display: none;
    flex-direction: column;
    width: 100%;
    padding-top: 3.5rem;
    background-color: #f8f9fa;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1001;
    justify-content: flex-start;
  }

  .navbar-menu.active {
    display: flex;
  }

  .navbar-menu li {
    margin: 0;
    width: auto;
  }

  .navbar-menu a,
  .dropdown-menu a {
    display: flex;
    padding: 0.25rem 0.5rem;
    width: 100%;
    box-sizing: border-box;
    transition: none;
    white-space: nowrap;
  }

  .navbar-logo-container {
    margin: 0.5rem 0;
  }

  .dropdown-menu {
    position: static;
    box-sizing: border-box;
    padding: 0;
    width: 100%;
    margin: 0;
    transform: translateX(20px);
  }

  .dropdown.active .dropdown-menu {
    display: block;
    position: static;
    padding-left: 0;
  }

  .dropdown-menu li {
    padding: 0 !important;
    margin: 0 !important;
  }

  .navbar-menu a,
  .dropdown-menu a {
    display: flex;
    align-items: center;
    padding: 0.5rem 0.5rem !important;
    min-height: 44px; /* For better touch targets */
    box-sizing: border-box;
    width: 100%;
    text-align: left;
  }

  .navbar-menu a:hover,
  .dropdown-menu a:hover,
  .navbar-menu a:active,
  .dropdown-menu a:active,
  .navbar-menu.active a:hover,
  .navbar-menu.active a:active,
  .dropdown-menu a:hover,
  .dropdown-menu a:active {
    background-color: transparent;
    color: #333;
  }

  .dropdown-menu li,
  .dropdown-menu li a {
    line-height: 1;
  }

  .dropdown {
    margin: 0 !important;
    padding: 0 !important;
  }

  .profile-dropdown {
    position: absolute;
    top: 0.5rem;
    right: 0.5rem;
    display: inline-block;
    padding-right: 20px;
    z-index: 1004;
  }

  .dropdown-menu,
  .profile-dropdown-menu {
    padding: 0.25rem 0;
  }

  .profile-dropdown-menu {
    right: 20px;
  }

  .profile-dropdown-arrow {
    right: 0px;
  }

  .profile-dropdown .profile-picture {
    margin-left: 0;
  }

  .profile-dropdown .profile-dropdown-menu {
    right: 0;
  }

  .profile-dropdown-menu a,
  .profile-dropdown-menu button {
    padding: 0.25rem 0.5rem;
  }

  .profile-dropdown.active .profile-dropdown-menu {
    display: block;
  }
}

@media (hover: none) {
  .navbar-menu a:hover,
  .dropdown-menu a:hover {
    background-color: transparent;
  }

  .navbar-menu a:active,
  .dropdown-menu a:active {
    opacity: 0.7;
  }

  .navbar-menu.active a,
  .navbar-menu.active a:hover,
  .navbar-menu.active .dropdown-menu a,
  .navbar-menu.active .dropdown-menu a:hover {
    background-color: transparent;
    color: #333;
  }
}

.navbar a,
.navbar button.hamburger-menu {
  min-height: 44px;
  min-width: 44px;
}

.navbar a:focus,
.navbar button.hamburger-menu:focus {
  outline: 2px solid #007bff;
  outline-offset: 2px;
}

.navbar a:focus:not(:focus-visible),
.navbar button.hamburger-menu:focus:not(:focus-visible) {
  outline: none;
}

@media screen and (-ms-high-contrast: active) {
  .navbar,
  .dropdown-menu {
    border: 1px solid currentColor;
  }

  .navbar button.hamburger-menu span {
    background: currentColor;
  }
}