/* rcQuiz.css */

/* Override body styles */
body {
  display: block;
  justify-content: initial;
  align-items: initial;
  height: auto;
  padding: 20px 0;
}

/* Quiz container styles */
.satrw-quiz-container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  min-height: 400px;
  width: 100%;
  max-width: 800px;
  margin: 0 auto;
  padding: 20px;
  box-sizing: border-box;
  background-color: #fff;
  border-radius: 15px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
  margin-top: 20px;
}

/* Welcome screen styles */
.satrw-welcome-screen {
  width: 100%;
}

.satrw-welcome-screen h1 {
  margin-bottom: 20px;
}

.satrw-welcome-header {
  text-align: center;
  margin-bottom: 20px;
}

.satrw-welcome-button-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin: 20px auto;
    width: 100%;
}

.satrw-welcome-button-container,
.satrw-new-quiz-button-container {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 20px;
  width: 100%;
}

.satrw-welcome-button:hover,
.satrw-resume-button:hover {
  background-color: #0056b3;
}

.satrw-welcome-button {
    font-size: 18px;
    padding: 10px 20px;
    cursor: pointer;
    background-color: #007bff;
    color: white;
    border: none;
    border-radius: 5px;
    transition: background-color 0.3s ease;
    margin: 5px 0;
    width: 300px;
    max-width: 100%; /* This ensures it doesn't overflow on smaller screens */
}

.satrw-resume-button {
  font-size: 18px;
  padding: 10px 20px;
  cursor: pointer;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 5px;
  transition: background-color 0.3s ease;
  margin: 5px 0;
  width: 100%;
}

.satrw-welcome-button:hover,
.satrw-new-quiz-button:hover {
  background-color: #0056b3;
}

/* Passage text styles */
.satrw-passage-text {
  margin-bottom: 20px;
  line-height: 1.6;
  text-align: left;
}

.satrw-passage-text p {
  margin-bottom: 1em;
  text-align: left;
}

/* Quiz summary styles */
.satrw-quiz-summary {
  margin-top: 20px;
  margin-bottom: 20px;
  max-height: 300px;
  overflow-y: auto;
  border: 1px solid #ccc;
  padding: 10px;
  padding-bottom: 20px;
  scrollbar-width: thin;
  scrollbar-color: #007bff #f0f0f0;
  width: 100%;
}

/* WebKit scrollbar styles */
.satrw-quiz-summary::-webkit-scrollbar {
  width: 8px;
}

.satrw-quiz-summary::-webkit-scrollbar-track {
  background: #f0f0f0;
  border-radius: 4px;
}

.satrw-quiz-summary::-webkit-scrollbar-thumb {
  background-color: #007bff;
  border-radius: 4px;
  border: 2px solid #f0f0f0;
}

/* Quiz instructions styles */
.satrw-quiz-instructions {
  margin: 20px 0;
  text-align: left;
  max-width: 800px;
  margin-left: auto;
  margin-right: auto;
}

.satrw-quiz-instructions p {
  margin-bottom: 15px;
  line-height: 1.5;
}

/* Summary item styles */
.satrw-summary-item {
  margin-bottom: 15px;
  padding: 10px;
  border-radius: 5px;
}

.satrw-summary-item.correct {
  background-color: #d4edda;
}

.satrw-summary-item.incorrect {
  background-color: #f8d7da;
}

/* Heading styles */
h2, h3 {
  color: #333;
  margin-bottom: 20px;
  text-align: left;
  width: 100%;
}

/* Question type styles */
.satrw-question-type {
  font-style: italic;
  color: #666;
  margin-bottom: 15px;
  text-align: left;
  width: 100%;
}

/* Options list styles */
.satrw-options-list {
  display: flex;
  flex-direction: column;
  gap: 10px;
  width: 100%;
  margin-bottom: 20px;
  align-items: flex-start;
  list-style-type: none;
  padding-left: 0;
}

.satrw-options-list li {
  margin-bottom: 0px;
  width: 100%;
  list-style-type: none;
}

.satrw-quiz-container .satrw-options-list,
.satrw-quiz-container .satrw-options-list li {
  list-style-type: none !important;
  padding-left: 0 !important;
}

/* Option label styles */
.satrw-option-label {
  display: flex;
  align-items: center;
  padding: 10px;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;
  background-color: #f8f9fa;
  text-align: left;
  width: 100%;
  box-sizing: border-box;
  justify-content: flex-start;
}

.satrw-option-text {
  text-align: left;
  width: 100%;
  margin-left: 10px;
  flex: 1;
}

.satrw-option-label input[type="radio"],
.satrw-option-label input[type="checkbox"] {
  margin-right: 10px;
  margin-top: 3px;
  flex-shrink: 0;
}

.satrw-option-label:hover {
  background-color: #e9ecef;
}

.satrw-option-label.selected {
  background-color: #cce5ff;
  font-weight: bold;
}

.satrw-option-label.correct {
  background-color: #d4edda;
}

.satrw-option-label.incorrect {
  background-color: #f8d7da;
}

/* Disabled checkbox styles */
input[type="checkbox"]:disabled + .option-text {
  opacity: 0.5;
  cursor: not-allowed;
}

/* Button container styles */
.satrw-button-container {
  margin-top: 20px;
  width: 100%;
  padding-bottom: 10px;
  text-align: left;
  display: flex;
  justify-content: flex-start;
}

/* Button styles */
button {
  background-color: #007bff;
  color: white;
  border: none;
  padding: 10px 20px;
  border-radius: 5px;
  cursor: pointer;
  font-size: 16px;
  transition: background-color 0.3s ease;
  margin-bottom: 0;
}

button:hover {
  background-color: #0056b3;
}

button:disabled {
  background-color: #cccccc;
  cursor: not-allowed;
}

/* Explanation styles */
.satrw-explanation {
  margin-top: 20px;
  padding: 10px;
  background-color: #f8f9fa;
  border-radius: 5px;
  width: 100%;
  text-align: left;
}

/* Answer status styles */
.satrw-answer-status {
  font-weight: bold;
  margin-bottom: 10px;
}

.satrw-answer-status.correct {
  color: #28a745;
}

.satrw-answer-status.incorrect {
  color: #dc3545;
}

/* Question text styles */
.satrw-question-text {
  width: 100%;
  text-align: left;
}

.satrw-saved-quiz-info {
    background-color: #f0f0f0;
    padding: 15px;
    border-radius: 5px;
    margin-bottom: 20px;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
    width: 100%;
    max-width: 800px;
    text-align: left;
}

.satrw-saved-quiz-info button {
    background-color: #4CAF50;
    font-weight: bold;
    color: white;
    padding: 10px 20px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    font-size: 16px;
    margin-top: 10px;
    transition: background-color 0.3s ease;
    width: 100%;
    box-sizing: border-box;
}

.satrw-saved-quiz-info button:hover {
  background-color: #45a049;
}

.satrw-saved-quiz-info .rc-resume-button {
    width: 100%;
    box-sizing: border-box;
}

/* Media query for mobile devices */
@media (max-width: 600px) {
  .options-grid {
    flex-direction: column;
  }

  .satrw-quiz-container {
    width: 100%;
    padding: 10px;
  }

  button {
    font-size: 14px;
    padding: 8px 16px;
  }

  .satrw-saved-quiz-info button {
    font-size: 14px;
  }
}

/* Override specific App.css styles */
#satrw-quiz-container {
  width: 100%;
  max-width: 800px;
  margin: 0 auto;
  padding: 20px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

#satrw-options-list {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
}

.radio-label {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding: 0px;
  width: 100%;
  background-color: #f8f9fa;
  border-radius: 5px;
  transition: background-color 0.3s ease;
  text-align: left;
}

.radio-label input {
  margin-right: 10px !important;
}

/* Ensure left alignment for all quiz elements */
.satrw-quiz-container > *,
.satrw-quiz-container .options-list,
.satrw-quiz-container .option-label,
.satrw-quiz-container .button-container {
  text-align: left !important;
  justify-content: flex-start !important;
  align-items: flex-start !important;
}