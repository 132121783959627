/* SynonymsQuiz.css */

* {
  box-sizing: border-box;
}

body {
  margin: 0;
  padding: 0;
  font-family: Arial, sans-serif;
  background-color: #f9f9f9;
  color: #333;
}

.syn-answer-options {
  display: grid;
  grid-template-columns: repeat(2, 1fr); /* Creates two equal columns */
  gap: 10px; /* Space between the buttons */
}

.syn-option-button {
  width: 100%; /* Ensure buttons take full width of the grid cell */
  min-height: 44px; /* Maintain a minimum height for buttons */
  display: flex; /* Use flexbox to center content */
  align-items: center; /* Center vertically */
  justify-content: center; /* Center horizontally */
  background-color: #f8f9fa; /* Background color */
  border: 1px solid #ced4da; /* Border */
  border-radius: 5px; /* Rounded corners */
  cursor: pointer; /* Pointer cursor on hover */
  transition: all 0.3s ease; /* Smooth transition */
  font-size: 16px; /* Font size */
  color: #333333; /* Text color */
}

.syn-option-button:hover:not(:disabled) {
  background-color: #e9ecef; /* Change background on hover */
}

.syn-option-button:disabled {
  cursor: not-allowed; /* Not allowed cursor */
  opacity: 0.7; /* Reduced opacity */
}

.syn-option-text {
  text-align: center; /* Center text inside the button */
  width: 100%; /* Ensure text takes full width */
}

.syn-option-button.answered {
  pointer-events: none; /* Disable pointer events */
}

.syn-option-button.selected:not(.correct):not(.incorrect) {
  background-color: #cce5ff; /* Background for selected */
  border-color: #007bff; /* Border color for selected */
  color: #004085; /* Text color for selected */
}

.syn-option-button.correct {
  background-color: #d4edda; /* Background for correct */
  border-color: #28a745; /* Border color for correct */
  color: #155724; /* Text color for correct */
}

.syn-option-button.incorrect {
  background-color: #f8d7da; /* Background for incorrect */
  border-color: #dc3545; /* Border color for incorrect */
  color: #721c24; /* Text color for incorrect */
}

.syn-saved-quiz-info {
  background-color: #f0f0f0;
  padding: 15px;
  border-radius: 5px;
  margin-bottom: 20px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  width: 100%;
  max-width: 800px;
  text-align: left;
}

.syn-resume-button {
  background-color: #4CAF50;
  font-weight: bold;
  color: white;
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 16px;
  margin-top: 10px;
  transition: background-color 0.3s ease;
  width: 100%;
}

.syn-resume-button:hover {
  background-color: #45a049;
}

.syn-quiz-container {
  position: relative;
  z-index: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  min-height: 400px;
  width: 100%;
  max-width: 800px;
  margin: 0 auto;
  padding: 20px;
  background-color: #fff;
  border-radius: 15px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
  overflow-x: hidden;
  margin-top: 20px;
}

.syn-quiz-container > h2 {
  position: relative;
  z-index: 2;
  background-color: #fff;
  padding: 10px;
  margin: 0 0 10px 0;
  width: 100%;
  text-align: center;
}

.syn-welcome-screen {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  width: 100%;
  height: 100%;
  min-height: 400px;
}

.syn-welcome-screen h2 {
  margin-bottom: 20px;
  width: 100%;
  text-align: center;
}

.syn-welcome-screen button {
  margin-top: 20px;
  align-self: center;
}

.syn-quiz-instructions {
  text-align: left;
  width: 100%;
}

.syn-quiz-completion {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  width: 100%;
}

.syn-flashcard {
  position: relative;
  z-index: 2;
  background-color: #f8f9fa;
  border-radius: 10px;
  padding: 20px;
  margin-bottom: 0px;
  box-shadow: 0 2px 5px rgba(0,0,0,0.1);
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
}

.syn-word-container {
  width: 100%;
  text-align: center;
  margin-bottom: 20px;
}

.syn-word-container h2 {
  margin: 0;
  padding: 10px;
  background-color: #e9ecef;
  border-radius: 5px;
  box-shadow: 0 1px 3px rgba(0,0,0,0.1);
  color: #1a1a1a;
  word-wrap: break-word;
  font-size: 18px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: normal;
}

.syn-word-container h2 .means {
  color: #666;
  font-size: 1em;
}

.means {
  white-space: nowrap;
  margin: 0;
  padding: 0;
}

.syn-quiz-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding: 10px 20px;
}

.syn-quiz-title {
  font-weight: bold;
}

.syn-quiz-info {
  display: flex;
  gap: 20px;
  font-weight: bold;
}

.syn-synonyms {
  display: flex;
  flex-direction: column;
  gap: 10px;
  width: 100%;
  align-items: stretch;
}

.syn-progress-bar {
  position: relative;
  z-index: 2;
  width: 100%;
  height: 20px;
  background-color: #e9ecef;
  border-radius: 10px;
  margin-bottom: 20px;
  overflow: hidden;
}

.syn-progress {
  height: 100%;
  background-color: #007bff;
  border-radius: 10px;
  transition: width 0.3s ease-in-out;
}

button {
  background-color: #007bff;
  color: white;
  border: none;
  padding: 10px 20px;
  border-radius: 5px;
  cursor: pointer;
  font-size: 16px;
  margin-top: 10px;
  max-width: 100%;
  white-space: normal;
  word-wrap: break-word;
  transition: background-color 0.3s ease;
}

button:hover {
  background-color: #0056b3;
}

button:disabled {
  background-color: #cccccc;
  cursor: not-allowed;
}

.syn-button-container {
  display: flex;
  justify-content: center;
  width: 100%;
  margin-top: 20px;
}

.syn-button-container button {
  max-width: 200px;
}

.syn-restart-button {
  margin: 0 auto;
  display: block;
}

.explanation {
  margin-top: 20px;
  padding: 15px;
  background-color: #f8f9fa;
  border-radius: 5px;
  border: 1px solid #e9ecef;
  word-wrap: break-word;
  overflow-wrap: break-word;
  width: 100%;
  color: #333333;
}

.answer-status {
  font-weight: bold;
  margin-bottom: 10px;
}

.answer-status.correct {
  color: #155724;
}

.answer-status.incorrect {
  color: #721c24;
}

.syn-quiz-summary {
  margin-top: 20px;
  max-height: 50vh;
  overflow-y: auto;
  border: 1px solid #ced4da;
  padding: 10px;
  scrollbar-width: thin;
  scrollbar-color: #007bff #f0f0f0;
  width: 100%;
}

.syn-quiz-summary::-webkit-scrollbar {
  width: 8px;
}

.syn-quiz-summary::-webkit-scrollbar-track {
  background: #f0f0f0;
  border-radius: 4px;
}

.syn-quiz-summary::-webkit-scrollbar-thumb {
  background-color: #007bff;
  border-radius: 4px;
  border: 2px solid #f0f0f0;
}

.syn-summary-item {
  margin-bottom: 15px;
  padding: 10px;
  border-radius: 5px;
  word-wrap: break-word;
  overflow-wrap: break-word;
  font-size: 14px;
}

.syn-summary-item.correct {
  background-color: #d4edda;
  color: #155724;
}

.syn-summary-item.incorrect {
  background-color: #f8d7da;
  color: #721c24;
}

p {
  word-wrap: break-word;
  overflow-wrap: break-word;
  color: #333333;
}

.syn-quiz-type-selection {
  width: 100%;
  max-width: 400px;
  margin-top: 20px;
  padding: 20px;
  background-color: #f8f9fa;
  border-radius: 10px;
  box-shadow: 0 2px 5px rgba(0,0,0,0.1);
  text-align: center; /* Center the content */
}

.syn-quiz-type-selection h3 {
  margin-bottom: 15px;
  color: #333;
  font-size: 18px;
  text-align: center; /* Center the text */
}

.syn-quiz-type-buttons {
  display: flex;
  justify-content: space-between;
  gap: 15px;
}

.syn-quiz-type-button {
  flex: 1;
  padding: 12px 20px;
  font-size: 16px;
  font-weight: bold;
  text-align: center;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: all 0.3s ease;
}

.syn-quiz-type-button.synonyms {
  background-color: #4CAF50;
  color: white;
}

.syn-quiz-type-button.antonyms {
  background-color: #2196F3;
  color: white;
}

.syn-quiz-type-button:hover {
  opacity: 0.9;
  transform: translateY(-2px);
}

@media (max-width: 600px) {
  .syn-quiz-container {
    padding: 10px;
  }

  .syn-option-button {
    background-color: #f8f9fa !important;
    color: #333333 !important;
  }

  .syn-flashcard {
    padding: 15px;
  }

  button {
    padding: 8px 16px;
    font-size: 14px;
  }

  .syn-quiz-summary {
    max-height: 60vh;
  }

  .syn-summary-item {
    font-size: 12px;
    padding: 8px;
  }

  .syn-quiz-container > h2 {
    font-size: 18px;
  }

  .syn-quiz-type-buttons {
    flex-direction: column;
  }

  .syn-quiz-type-button {
    width: 100%;
  }
}

@media (min-width: 601px) and (max-width: 1024px) {
  .syn-quiz-summary {
    max-height: 55vh;
  }

  .syn-summary-item {
    font-size: 13px;
  }
}

@media (min-width: 1025px) {
  .syn-quiz-summary {
    max-height: 50vh;
  }
}

.navigation-menu {
  position: relative;
  z-index: 1;
}