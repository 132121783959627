/* VocabularyBuilder.css */

body, .vb-container {
  font-family: 'Arial', sans-serif;
  background-color: #f9f9f9;
  margin: 0;
  padding: 0;
}

body {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
}

button {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}

.vb-container {
  display: flex;
  justify-content: center;
  padding: 0px;
  min-height: 100%;
  flex-grow: 1;
  margin-top: 20px;
}

.vb-main {
  background-color: #ffffff;
  border-radius: 15px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
  padding: 20px;
  width: 100%;
  max-width: 800px;
  min-height: auto;
  display: flex;
  flex-direction: column;
}

.vb-title {
  text-align: center;
  font-size: 24px;
  font-weight: bold;
}

.vb-content-wrapper {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  overflow-y: visible;
  max-height: none;
}

.vb-word-grid,
.vb-word-detail,
.vb-quiz-mode {
  width: 100%;
  max-width: 760px;
  margin: 0 auto;
  position: relative;
  padding-top: 35px;
}

.vb-word-grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(150px, 1fr));
  gap: 10px;
  padding: 10px;
}

.vb-word-item-container {
  position: relative;
}

.vb-word-item-container .vb-favorite-button {
  position: absolute;
  top: 0px;
  right: 15px;
}

.vb-word-item {
  background-color: #f9f9f9;
  border: 1px solid #eaeaea;
  border-radius: 8px;
  padding: 10px;
  cursor: pointer;
  transition: background-color 0.3s ease;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 120px;
  position: relative;
  overflow: hidden;
}

.vb-word-item:hover {
  background-color: #f0f0f0;
}

.vb-word-content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  width: 100%;
  height: 100%;
  padding: 5px;
}

.vb-word-grid .vb-word {
  font-size: 16px;
  font-weight: bold;
  margin-bottom: 5px;
  word-break: break-word;
  max-width: 100%;
}

.vb-word-grid .vb-part-of-speech {
  font-size: 12px;
  color: #666;
  max-width: 100%;
}

.vb-word-header {
  position: relative;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  margin-bottom: 15px;
}

.vb-word-info {
  display: flex;
  align-items: center;
  padding-right: 30px;
}

.vb-word-detail .vb-word,
.vb-quiz-mode .vb-word {
  font-size: 24px;
  color: #1a1a1a;
  word-wrap: break-word;
  margin-right: 10px;
}

.vb-word-detail .vb-part-of-speech,
.vb-quiz-mode .vb-part-of-speech {
  font-style: italic;
  font-weight: normal;
  font-size: 24px;
  color: #666;
  margin-right: 10px;
}

.vb-word-detail,
.vb-quiz-mode {
  margin-top: 0px;
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  overflow-y: visible;
}

.vb-quiz-button,
.vb-back-button,
.vb-submit-button {
  padding: 10px 20px;
  margin-top: 0px;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 16px;
  transition: background-color 0.3s ease;
}

.vb-quiz-button:hover,
.vb-back-button:hover,
.vb-submit-button:hover {
  background-color: #0056b3;
}

.vb-button-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  gap: 15px;
  margin-top: 5px;
  padding-top: 5px;
  margin-bottom: 20px;
}

.vb-button-container button {
  margin-top: 5px;
}

.vb-nav-button {
  background: none;
  border: none;
  cursor: pointer;
  color: #007bff;
  transition: none;
  padding: 5px;
}

.vb-nav-button:hover {
  color: #0056b3;
  background-color: transparent;
}

.vb-quiz-options {
  display: flex;
  flex-direction: column;
  gap: 5px;
  width: 100%;
  align-items: stretch;
  margin-bottom: 10px;
}

.vb-quiz-option {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  min-height: 44px;
  padding: 10px 15px;
  background-color: #f8f9fa;
  border: 1px solid #ced4da;
  border-radius: 5px;
  cursor: pointer;
  transition: all 0.3s ease;
  width: 100%;
  margin-bottom: 0px;
  font-size: 16px;
  color: #333333;
}

.vb-quiz-option:hover:not(:disabled) {
  background-color: #e9ecef;
}

.vb-quiz-option:disabled {
  cursor: not-allowed;
  opacity: 0.7;
}

.vb-quiz-option-text {
  word-break: break-word;
  text-align: left;
}

.vb-quiz-option.answered {
  pointer-events: none;
}

.vb-quiz-option.selected:not(.correct):not(.incorrect) {
  background-color: #cce5ff;
  border-color: #007bff;
  color: #004085;
}

.vb-quiz-option.correct {
  background-color: #d4edda;
  border-color: #28a745;
  color: #155724;
}

.vb-quiz-option.incorrect {
  background-color: #f8d7da;
  border-color: #dc3545;
  color: #721c24;
}

.vb-quiz-result {
  margin-top: 5px;
  margin-bottom: 5px;
  padding: 15px;
  background-color: #f8f9fa;
  border-radius: 5px;
  border: 1px solid #e9ecef;
  word-wrap: break-word;
  overflow-wrap: break-word;
  width: 100%;
  color: #333333;
}

.vb-quiz-result .answer-status {
  font-weight: bold;
  margin-bottom: 5px;
  line-height: 1.5;
}

.vb-quiz-result .answer-status.correct {
  color: #155724;
}

.vb-quiz-result .answer-status.incorrect {
  color: #721c24;
}

.vb-quiz-result .correct-answer {
  display: block;
  margin-top: 0px;
  font-weight: normal;
  color: #333333;
}

.vocab-quiz-play-button {
  background: none;
  border: none;
  cursor: pointer;
  font-size: 1.2em;
  padding: 5px;
  display: flex;
  align-items: center;
}

.vocab-quiz-audio {
  display: none;
}

.vb-word-item.easy {
  background-color: #e6f7ff;
  border-color: #91d5ff;
}

.vb-word-item.medium {
  background-color: #fff7e6;
  border-color: #ffd591;
}

.vb-word-item.hard {
  background-color: #fff1f0;
  border-color: #ffa39e;
}

.vb-word-item.easy:hover {
  background-color: #bae7ff;
}

.vb-word-item.medium:hover {
  background-color: #ffe7ba;
}

.vb-word-item.hard:hover {
  background-color: #ffccc7;
}

/* Welcome Section Styles */
.vb-welcome-section {
  text-align: left;
  padding: 20px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.vb-welcome-section h1 {
  font-size: 28px;
  margin-bottom: 10px;
  text-align: center;
  width: 100%;
}

.vb-welcome-section p {
  font-size: 16px;
  line-height: 1.5;
  margin-bottom: 0px;
}

.vb-welcome-section ul {
  text-align: left;
  margin-bottom: 0px;
  padding-left: 20px;
}

.vb-welcome-section li {
  font-size: 16px;
  line-height: 1.5;
  margin-bottom: 0px;
}

.vb-display-grid-button {
  background-color: #3498db;
  color: white;
  border: none;
  padding: 12px 24px;
  font-size: 18px;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;
  margin-top: 20px;
  align-self: center;
}

.vb-display-grid-button:hover {
  background-color: #2980b9;
}

.vb-favorite-container {
  position: absolute;
  top: 0;
  right: 0;
}

.vb-favorite-button {
  position: absolute;
  top: -20px;
  right: 20px;
  background: none;
  border: none;
  font-size: 1.6em;
  cursor: pointer;
  color: #ccc;
  transition: color 0.3s ease;
  padding: 0;
}

.vb-favorite-button.favorited {
  color: gold;
}

.vb-favorite-button:hover {
  background-color: transparent;
}

.vb-favorites {
  margin-top: 2em;
}

.vb-favorites-grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(150px, 1fr));
  gap: 10px;
  padding: 10px;
}

.vb-favorites-grid .vb-word-item {
  background-color: #f9f9f9;
  border: 1px solid #eaeaea;
  border-radius: 8px;
  padding: 10px;
  cursor: pointer;
  transition: background-color 0.3s ease;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 120px;
  position: relative;
  overflow: hidden;
}

.vb-favorites-grid .vb-word-item:hover {
  background-color: #f0f0f0;
}

.vb-favorites-grid .vb-word-content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  width: 100%;
  height: 100%;
  padding: 5px;
}

.vb-favorites-grid .vb-word {
  font-size: 16px;
  font-weight: bold;
  margin-bottom: 5px;
  word-break: break-word;
  max-width: 100%;
}

.vb-favorites-grid .vb-part-of-speech {
  font-size: 12px;
  color: #666;
  max-width: 100%;
}

.vb-favorites-grid .vb-word-item.easy {
  background-color: #e6f7ff;
  border-color: #91d5ff;
}

.vb-favorites-grid .vb-word-item.medium {
  background-color: #fff7e6;
  border-color: #ffd591;
}

.vb-favorites-grid .vb-word-item.hard {
  background-color: #fff1f0;
  border-color: #ffa39e;
}

.vb-favorites-grid .vb-word-item.easy:hover {
  background-color: #bae7ff;
}

.vb-favorites-grid .vb-word-item.medium:hover {
  background-color: #ffe7ba;
}

.vb-favorites-grid .vb-word-item.hard:hover {
  background-color: #ffccc7;
}

.vb-tabs {
  display: flex;
  justify-content: center;
  margin-bottom: 20px;
  background-color: #f0f0f0;
  border-radius: 25px;
  padding: 5px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
}

.vb-tab {
  display: flex;
  align-items: center;
  gap: 8px;
  padding: 10px 20px;
  border: none;
  background-color: transparent;
  color: #333;
  font-size: 16px;
  font-weight: 600;
  cursor: pointer;
  transition: all 0.3s ease;
  border-radius: 20px;
}

.vb-tab:hover {
  background-color: #e0e0e0;
}

.vb-tab.active {
  background-color: #007bff;
  color: white;
}

.vb-tab svg {
  transition: transform 0.3s ease;
}

.vb-tab.active svg {
  transform: scale(1.2);
}

/* Media Queries for Mobile Optimization */
@media (max-width: 768px) {
  .vb-favorite-button {
    top: -15px;
    right: 15px;
    font-size: 1.4em;
  }

  .vb-container {
    padding: 10px;
    margin-top: 20px;
  }

  .vb-main {
    padding: 15px;
    min-height: auto;
  }

  .vb-title {
    font-size: 20px;
  }

  .vb-word-detail .vb-word,
  .vb-quiz-mode .vb-word {
    font-size: 20px;
  }

  .vb-word-detail .vb-part-of-speech,
  .vb-quiz-mode .vb-part-of-speech {
    font-size: 14px;
  }

  .vb-quiz-button,
  .vb-back-button,
  .vb-submit-button {
    font-size: 14px;
  }

  .vb-button-container {
    gap: 10px;
    padding-top: 5px;
  }

  .vb-word-grid,
  .vb-word-detail,
  .vb-quiz-mode {
    max-width: 100%;
  }

  .vb-welcome-section h1 {
    font-size: 24px;
  }

  .vb-welcome-section p,
  .vb-welcome-section li {
    font-size: 14px;
  }

  .vb-display-grid-button {
    font-size: 16px;
    padding: 10px 20px;
  }

  .vb-favorites-grid {
    max-width: 100%;
  }
}

@media (max-width: 480px) {
  .vb-container {
    padding: 5px;
    margin-top: 20px;
  }

  .vb-main {
    padding: 10px;
    min-height: auto;
  }

  .vb-title {
    font-size: 18px;
  }

  .vb-favorite-button {
    top: -10px;
    right: 20px;
    font-size: 1.2em;
  }

  .vb-word-info {
    padding-right: 25px;
  }

  .vb-word-detail .vb-word,
  .vb-quiz-mode .vb-word {
    font-size: 18px;
    margin-right: 5px;
  }

  .vb-word-detail .vb-part-of-speech,
  .vb-quiz-mode .vb-part-of-speech {
    font-size: 18px;
    margin-right: 5px;
  }

  .vb-quiz-button,
  .vb-back-button,
  .vb-submit-button {
    font-size: 12px;
    padding: 8px 16px;
  }

  .vb-word-grid {
    grid-template-columns: repeat(auto-fill, minmax(120px, 1fr));
    gap: 10px;
  }

  .vb-button-container {
    flex-direction: column;
    align-items: stretch;
    padding-top: 5px;
  }

  .vb-button-container button {
    width: 100%;
  }

  .vb-word-header {
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
  }

  .vocab-quiz-play-button {
    font-size: 1em;
    padding: 3px;
  }

  .vb-welcome-section h1 {
    font-size: 20px;
  }

  .vb-welcome-section p,
  .vb-welcome-section li {
    font-size: 12px;
  }

  .vb-display-grid-button {
    font-size: 14px;
    padding: 8px 16px;
  }

  .vb-favorites-grid {
    grid-template-columns: repeat(auto-fill, minmax(120px, 1fr));
    gap: 10px;
  }
}