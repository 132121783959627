/* tcseQuiz.css */
.tcse-quiz-container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  min-height: 400px;
  width: 100%;
  max-width: 800px;
  margin: 0 auto;
  padding: 20px;
  background-color: #fff;
  border-radius: 15px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
  margin-top: 20px;
}

.tcse-welcome-screen {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  width: 100%;
  height: 100%;
  min-height: 400px;
}

.tcse-restart-button {
  display: block;
  margin: 10px auto;
  width: fit-content;
}

.tcse-welcome-screen button {
  margin: 20px auto; /* Keep the start button centered */
}

.tcse-welcome-screen h2 {
  text-align: center;
  width: 100%;
}

.tcse-welcome-screen button,
.tcse-quiz-container > button {
  font-size: 18px;
  padding: 10px 20px;
  cursor: pointer;
  margin-top: 10px;
}

.tcse-quiz-summary {
  margin-top: 10px;
  margin-bottom: 10px;
  max-height: 300px;
  overflow-y: auto;
  border: 1px solid #ccc;
  padding: 10px;
  padding-bottom: 20px;
  scrollbar-width: thin;
  scrollbar-color: #007bff #f0f0f0;
  width: 100%;
}

/* WebKit browsers (Chrome, Safari, etc.) */
.tcse-quiz-summary::-webkit-scrollbar {
  width: 8px;
}

.tcse-quiz-summary::-webkit-scrollbar-track {
  background: #f0f0f0;
  border-radius: 4px;
}

.tcse-quiz-summary::-webkit-scrollbar-thumb {
  background-color: #007bff;
  border-radius: 4px;
  border: 2px solid #f0f0f0;
}

.tcse-quiz-instructions {
  margin: 10px 0;
  text-align: left;
  max-width: 800px;
  margin-left: auto;
  margin-right: auto;
}

.tcse-quiz-instructions p {
  margin-bottom: 10px;
  line-height: 1.5;
}

/* For Firefox */
@supports (scrollbar-color: #007bff #f0f0f0) {
  .tcse-quiz-summary {
    scrollbar-color: #007bff #f0f0f0;
  }
}

/* Increase scrollbar width for touch devices */
@media (pointer: coarse) {
  .tcse-quiz-summary::-webkit-scrollbar {
    width: 12px;
  }
}

.tcse-summary-item {
  margin-bottom: 15px;
  padding: 10px;
  border-radius: 5px;
}

.tcse-summary-item.correct {
  background-color: #d4edda;
}

.tcse-summary-item.incorrect {
  background-color: #f8d7da;
}

h2 {
  color: #333;
  margin-bottom: 10px;
}

.tcse-question-type {
  font-style: italic;
  color: #666;
  margin-bottom: 10px;
  text-align: left; /* Add this line */
  width: 100%; /* Add this line */
}

.tcse-options-grid {
  display: flex;
  gap: 20px;
  margin-bottom: 10px;
  width: 100%;
  flex-wrap: wrap; /* Allow items to wrap to the next line */
}

.tcse-option-column {
  flex: 1 1 calc(33.33% - 20px); /* Adjust flex basis to fit 3 columns with gap */
  display: flex;
  flex-direction: column;
  gap: 10px;
  width: 100%;
}

.tcse-option-label {
  display: flex;
  align-items: center;
  padding: 10px;
  border: 1px solid #ddd;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.tcse-option-label:hover {
  background-color: #f0f0f0;
}

.tcse-option-label.selected {
  background-color: #e6f3ff;
  border-color: #007bff;
}

.tcse-option-label.correct {
  background-color: #d4edda;
  border-color: #28a745;
}

.tcse-option-label.incorrect {
  background-color: #f8d7da;
  border-color: #dc3545;
}

.tcse-option-label input {
  margin-right: 10px;
}

.tcse-option-text {
  flex: 1;
}

input[type="checkbox"]:disabled + .tcse-option-text {
  opacity: 0.5;
  cursor: not-allowed;
}

button {
  background-color: #007bff;
  color: white;
  border: none;
  padding: 10px 20px;
  border-radius: 5px;
  cursor: pointer;
  font-size: 16px;
  transition: background-color 0.3s ease;
  margin: 10px 0;
  display: inline-block;
  width: auto;
}

button:hover {
  background-color: #0056b3;
}

button:disabled {
  background-color: #cccccc;
  cursor: not-allowed;
}

.tcse-button-container {
  width: 100%;
  padding-bottom: 10px;
  display: flex;
  justify-content: flex-start; /* Change from center to flex-start */
}

.tcse-explanation {
  margin-top: 20px;
  padding: 10px;
  background-color: #f8f9fa;
  border-radius: 5px;
  width: 100%;
}

.tcse-answer-status {
  font-weight: bold;
  margin-bottom: 10px;
}

.tcse-answer-status.correct {
  color: #28a745;
}

.tcse-answer-status.incorrect {
  color: #dc3545;
}

.tcse-question-text {
  width: 100%;
  text-align: left;
}

.tcse-saved-quiz-info {
  background-color: #f0f0f0;
  padding: 15px;
  border-radius: 5px;
  margin-bottom: 20px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  width: 100%;
  max-width: 800px;
  text-align: left;
}

.tcse-saved-quiz-info button {
  background-color: #4CAF50;
  font-weight: bold;
  color: white;
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 16px;
  margin-top: 10px;
  transition: background-color 0.3s ease;
  width: 100%;
}

.tcse-saved-quiz-info button:hover {
  background-color: #45a049;
}

@media (max-width: 600px) {
  .tcse-options-grid {
    gap: 10px;
  }
  
  .tcse-welcome-screen button,
  .tcse-quiz-container > button {
    font-size: 16px;
  }

  h2 {
    font-size: 24px;
  }

  .tcse-question-type {
    font-size: 14px;
  }

  .tcse-option-label {
    font-size: 14px;
  }

  button {
    font-size: 14px;
  }

  .tcse-answer-status {
    font-size: 16px;
  }

  .tcse-question-text {
    font-size: 16px;
  }

  .tcse-saved-quiz-info button {
    font-size: 14px;
  }

  .tcse-option-column {
    flex: 1 1 calc(33.33% - 10px); /* Maintain 3 columns on smaller screens */
  }
}