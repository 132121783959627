/* Quiz.css */
body, .vocab-quiz-wrapper, .vocab-quiz-main {
  font-family: 'Arial', sans-serif;
  background-color: #f9f9f9;
  margin: 0;
  padding: 0;
}

body {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
}

button {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}

.vocab-quiz-wrapper {
  display: flex;
  justify-content: center;
  padding: 0px;
  min-height: 100%;
  flex-grow: 1;
  margin-top: 20px;
}

.vocab-quiz-main {
  background-color: #ffffff;
  border-radius: 15px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
  padding: 20px;
  width: 100%;
  max-width: 800px;
}

.vocab-quiz-main h1,
.vocab-quiz-main h2,
.vocab-quiz-main p {
  margin-top: 0;
  padding-bottom: 10px;
}

.vocab-quiz-header {
  text-align: left;
  margin-bottom: 20px;
  position: relative;
  z-index: 1; /* Ensure it's above other elements */
}

.vocab-quiz-question-number {
  font-size: 22px;
  font-weight: bold;
  margin-bottom: 10px;
  display: block;
}

.vocab-quiz-instructions h2 {
  text-align: center;
}

.vocab-quiz-instructions .start-quiz-btn,
.vocab-quiz-main .restart-quiz-btn {
  display: block;
  margin: 20px auto 0;
}

.vocab-quiz-instructions-list {
  padding-left: 20px;
}

.vocab-quiz-instructions-list li {
  margin-bottom: 10px;
}

.vocab-quiz-instructions select {
  margin: 20px auto 0;
  width: 50%;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
  background-color: #fff;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  display: block;
}

.vocab-quiz-instructions option {
  padding: 5px;
  background-color: #f0f0f0;
  color: #333;
}

.vocab-quiz-word-container {
  display: flex;
  align-items: center;
  margin-bottom: 20px;
  flex-wrap: wrap;
}

.vocab-quiz-word {
  display: inline-flex;
  align-items: center;
  font-size: 24px;
  margin-right: 10px;
  margin-bottom: 0;
  line-height: 1; /* Add this line */
}

.vocab-quiz-part-of-speech {
  font-weight: normal;
  color: #6c757d;
  margin-left: 5px;
}

.vocab-quiz-play-button {
  background: none;
  border: none;
  cursor: pointer;
  padding: 0;
  font-size: 24px;
  line-height: 1;
  color: #007bff;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  vertical-align: middle;
  width: 32px;
  height: 32px;
  margin-left: 5px;
  position: relative; /* Add this line */
  top: -3px; /* Add this line to fine-tune vertical alignment */
}

.vocab-quiz-play-button:hover,
.vocab-quiz-play-button:focus {
  color: #007bff;
  background: none;
  outline: none;
}

.vocab-quiz-audio {
  display: none;
}

.vocab-quiz-options {
  list-style: none;
  padding: 0;
}

.vocab-quiz-options li {
  margin-bottom: 10px;
}

.vocab-quiz-options label {
  display: flex;
  align-items: center;
}

.vocab-quiz-options input[type="radio"] {
  margin-right: 10px;
}

.vocab-quiz-button {
  padding: 10px 20px;
  margin-top: 20px;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 16px;
  transition: background-color 0.3s ease;
}

.vocab-quiz-button:disabled {
  background-color: #ccc;
  cursor: not-allowed;
}

.vocab-quiz-result {
  text-align: center;
  margin-top: 20px;
}

.vocab-quiz-result-score {
  font-size: 24px;
  font-weight: bold;
  margin-bottom: 10px;
}

.vocab-quiz-result-percentage {
  font-size: 18px;
}

.vocab-quiz-restart-button {
  margin-top: 20px;
}

.vocab-quiz-feedback-container {
  background-color: #f9f9f9;
  border: 1px solid #eaeaea;
  border-radius: 8px;
  padding: 10px;
  margin-bottom: 10px;
}

.vocab-quiz-feedback.correct {
  color: #28a745;
}

.vocab-quiz-feedback.incorrect {
  color: #dc3545;
}

/* Styles for the summary section */
.quiz-summary {
  margin-top: 20px;
  max-height: 50vh;
  overflow-y: auto;
  border: 1px solid #ced4da;
  padding: 10px;
  scrollbar-width: thin;
  scrollbar-color: #007bff #f0f0f0;
  width: 100%;
}

.quiz-summary::-webkit-scrollbar {
  width: 8px;
}

.quiz-summary::-webkit-scrollbar-track {
  background: #f0f0f0;
  border-radius: 4px;
}

.quiz-summary::-webkit-scrollbar-thumb {
  background-color: #007bff;
  border-radius: 4px;
  border: 2px solid #f0f0f0;
}

.summary-item {
  margin-bottom: 15px;
  padding: 10px;
  border-radius: 5px;
  word-wrap: break-word;
  overflow-wrap: break-word;
  font-size: 14px;
}

.summary-item.correct {
  background-color: #d4edda;
  color: #155724;
}

.summary-item.incorrect {
  background-color: #f8d7da;
  color: #721c24;
}

p {
  word-wrap: break-word;
  overflow-wrap: break-word;
  color: #333333;
}

/* Media Queries for Mobile Optimization */
@media (max-width: 768px) {
  .vocab-quiz-wrapper {
    padding: 10px;
  }

  .vocab-quiz-main {
    padding: 15px;
  }

  .vocab-quiz-header {
    margin-top: 60px; /* Adjust this value based on your menu bar height */
  }

  .vocab-quiz-question-number {
    font-size: 18px;
  }

  .vocab-quiz-options li {
    font-size: 16px;
  }

  .vocab-quiz-button {
    font-size: 14px;
  }

  .vocab-quiz-result-score {
    font-size: 20px;
  }

  .vocab-quiz-result-percentage {
    font-size: 16px;
  }
}

@media (max-width: 480px) {
  .vocab-quiz-wrapper {
    padding: 5px;
  }

  .vocab-quiz-main {
    padding: 10px;
  }

  .vocab-quiz-header {
    margin-top: 50px; /* Adjust if needed for smaller screens */
  }

  .vocab-quiz-question-number {
    font-size: 16px;
  }

  .vocab-quiz-options li {
    font-size: 14px;
  }

  .vocab-quiz-button {
    font-size: 12px;
    padding: 8px 16px;
  }

  .vocab-quiz-result-score {
    font-size: 18px;
  }

  .vocab-quiz-result-percentage {
    font-size: 14px;
  }

  .vocab-quiz-word {
    font-size: 20px;
  }

  .vocab-quiz-play-button {
    font-size: 20px;
  }
}

/* New styles for the WelcomeScreen buttons */
.vocab-saved-quiz-info {
  background-color: #f0f0f0;
  padding: 15px;
  border-radius: 5px;
  margin-bottom: 20px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  width: 100%;
  max-width: 800px;
  text-align: left;
}

.vocab-saved-quiz-info button {
  background-color: #4CAF50;
  font-weight: bold;
  color: white;
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 16px;
  margin-top: 10px;
  transition: background-color 0.3s ease;
  width: 100%;
}

.vocab-saved-quiz-info button:hover {
  background-color: #45a049;
}