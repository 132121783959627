/* mathQuiz.css */

/* Override body styles */
body {
  display: block;
  justify-content: initial;
  align-items: initial;
  height: auto;
  padding: 20px 0;
}

/* Quiz container styles */
.math-quiz-container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  min-height: 400px;
  width: 100%;
  max-width: 800px;
  margin: 0 auto;
  padding: 20px;
  box-sizing: border-box;
  background-color: #fff;
  border-radius: 15px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
  margin-top: 20px;
  position: relative;
}

/* Tabs container */
.math-tabs {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-bottom: 20px;
  border-bottom: 2px solid #ccc;
  width: 100%;
}

/* Individual tab styles */
.math-tab {
  background-color: transparent;
  border: none;
  padding: 10px 20px;
  cursor: pointer;
  font-size: 18px;
  color: #555;
  margin-right: 10px;
  transition: color 0.3s ease, border-bottom 0.3s ease;
  border-bottom: 2px solid transparent;
}

.math-tab:hover {
  color: #007bff;
}

.math-tab.active {
  color: #007bff;
  border-bottom: 2px solid #007bff;
  font-weight: bold;
}

/* Welcome screen styles */
.math-welcome-screen {
  width: 100%;
}

.math-welcome-screen h1 {
  margin-bottom: 20px;
}

.math-welcome-screen button,
.math-quiz-container > button {
  font-size: 18px;
  padding: 10px 20px;
  cursor: pointer;
}

.math-welcome-header {
  text-align: center;
  margin-bottom: 20px;
}

.math-welcome-button-container,
.math-new-quiz-button-container {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 20px;
  width: 100%;
  flex-wrap: wrap;
}

.math-welcome-button,
.math-new-quiz-button,
.math-download-pdf-button {
  font-size: 18px;
  padding: 10px 20px;
  cursor: pointer;
  border: none;
  border-radius: 5px;
  transition: background-color 0.3s ease;
  margin: 10px;
  flex: 1;
  min-width: 200px;
  max-width: 300px;
}

.math-welcome-button,
.math-new-quiz-button {
  background-color: #007bff;
  color: white;
}

.math-welcome-button:hover,
.math-new-quiz-button:hover {
  background-color: #0056b3;
}

.math-download-pdf-button {
  background-color: #4CAF50;
  color: white;
}

.math-download-pdf-button:hover {
  background-color: #45a049;
}

.math-quiz-summary {
  margin-top: 20px;
  margin-bottom: 20px;
  border: 1px solid #ccc;
  padding: 10px;
  width: 100%;
  box-sizing: border-box;
  max-height: 400px;
  overflow-y: auto;
  padding-right: 20px;
}

.math-quiz-summary::-webkit-scrollbar {
  width: 10px;
}

.math-quiz-summary::-webkit-scrollbar-track {
  background: #f1f1f1;
}

.math-quiz-summary::-webkit-scrollbar-thumb {
  background: #888;
  border-radius: 5px;
}

.math-quiz-summary::-webkit-scrollbar-thumb:hover {
  background: #555;
}

.math-quiz-completion {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
}

.math-quiz-completion h2,
.math-quiz-completion h3 {
  text-align: center;
}

.math-question-instruction {
  font-style: italic;
  color: #666666;
  font-weight: bold;
}

.math-quiz-instructions {
  margin: 20px 0;
  text-align: left;
  max-width: 800px;
  margin-left: auto;
  margin-right: auto;
}

.math-quiz-instructions p {
  margin-bottom: 15px;
  line-height: 1.5;
}

.math-summary-item {
  margin-bottom: 15px;
  padding: 10px;
  border-radius: 5px;
  word-wrap: break-word;
  overflow-wrap: break-word;
}

.math-summary-item.correct {
  background-color: #d4edda;
}

.math-summary-item.incorrect {
  background-color: #f8d7da;
}

.math-summary-item p {
  margin-bottom: 5px;
}

.math-summary-item .math-question {
  font-weight: bold;
}

.math-summary-item .math-explanation {
  margin-top: 10px;
  padding: 10px;
  background-color: #f8f9fa;
  border-radius: 5px;
}

/* Heading styles */
h2, h3 {
  color: #333;
  margin-bottom: 5px;
  text-align: left;
  width: 100%;
}

/* Question styles */
.math-question-text {
  font-size: 18px;
  margin-bottom: 0px;
  line-height: 1.6;
  text-align: left;
  width: 100%;
  word-wrap: break-word;
  overflow-wrap: break-word;
  hyphens: auto;
  padding: 10px;
}

/* MathML specific styles */
math {
  font-size: 16px;
  max-width: 100%;
  white-space: normal;
  word-break: break-word;
  overflow-wrap: break-word;
  display: inline-block;
  vertical-align: middle;
}

/* Style for individual math elements */
math * {
  max-width: 100%;
}

/* Ensure proper alignment of fractions */
mfrac {
  vertical-align: -0.5em;
}

/* Adjust spacing for operators and numbers */
mo, mn {
  padding: 0 0.2em;
}

/* Options list styles */
.math-options-list {
  display: flex;
  flex-direction: column;
  gap: 10px;
  width: 100%;
  margin-top: 0px;
  margin-bottom: 0px;
  align-items: flex-start;
}

/* Option label styles */
.math-option-label {
  display: flex;
  align-items: center;
  padding: 10px;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;
  background-color: #f8f9fa;
  text-align: left;
  width: 100%;
  box-sizing: border-box;
  justify-content: flex-start;
}

.math-option-text {
  text-align: left;
  width: 100%;
  margin-left: 10px;
  flex: 1;
  word-wrap: break-word;
  overflow-wrap: break-word;
}

.math-option-label input[type="radio"],
.math-option-label input[type="checkbox"] {
  margin-right: 10px;
  margin-top: 3px;
  flex-shrink: 0;
}

.math-option-label:hover {
  background-color: #e9ecef;
}

.math-option-label.selected {
  background-color: #cce5ff;
  font-weight: bold;
}

.math-option-label.correct {
  background-color: #d4edda;
}

.math-option-label.incorrect {
  background-color: #f8d7da;
}

/* Button container styles */
.math-button-container {
  margin-top: 10px;
  width: 100%;
  padding-bottom: 10px;
  text-align: left;
  display: flex;
  justify-content: flex-start;
}

/* Button styles */
button {
  background-color: #007bff;
  color: white;
  border: none;
  padding: 10px 20px;
  border-radius: 5px;
  cursor: pointer;
  font-size: 16px;
  transition: background-color 0.3s ease;
  margin-bottom: 0;
  width: auto;
}

button:hover {
  background-color: #0056b3;
}

button:disabled {
  background-color: #cccccc;
  cursor: not-allowed;
}

/* Explanation styles */
.math-explanation {
  margin-top: 20px;
  padding: 15px;
  background-color: #f8f9fa;
  border-radius: 5px;
  width: 100%;
  text-align: left;
  word-wrap: break-word;
  overflow-wrap: break-word;
}

.math-explanation .correct-answer {
  font-weight: bold;
  color: #28a745;
  margin-bottom: 10px;
}

.math-explanation-text {
  word-wrap: break-word;
  overflow-wrap: break-word;
  hyphens: auto;
}

/* Answer status styles */
.math-answer-status {
  font-weight: bold;
  margin-bottom: 10px;
}

.math-answer-status.correct {
  color: #28a745;
}

.math-answer-status.incorrect {
  color: #dc3545;
}

.math-saved-quiz-info {
  background-color: #f0f0f0;
  padding: 15px;
  border-radius: 5px;
  margin-bottom: 20px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  width: 100%;
  max-width: 800px;
  text-align: left;
}

.math-saved-quiz-info button {
  background-color: #4CAF50;
  font-weight: bold;
  color: white;
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 16px;
  margin-top: 10px;
  transition: background-color 0.3s ease;
  width: 100%;
}

.math-saved-quiz-info button:hover {
  background-color: #45a049;
}

.larger-inline-math {
  font-size: 1.6em;
}

/* Media query for mobile devices */
@media (max-width: 600px) {
  .math-options-list {
    flex-direction: column;
  }

  .math-quiz-container {
    width: 100%;
    padding: 10px;
    margin-top: 20px;
  }

  button {
    font-size: 14px;
    padding: 8px 16px;
  }

  .math-new-quiz-button,
  .math-download-pdf-button {
    font-size: 16px;
    padding: 8px 16px;
    min-width: 150px;
  }

  .math-saved-quiz-info button {
    font-size: 14px;
  }

  .math-quiz-summary {
    max-height: 300px;
  }

  .math-tab {
    font-size: 16px;
    padding: 8px 12px;
  }
}

/* Override specific App.css styles */
#math-quiz-container {
  width: 100%;
  max-width: 800px;
  margin: 0 auto;
  padding: 20px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

#math-options-list {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
}

/* Ensure left alignment for all quiz elements */
.math-quiz-container > *,
.math-quiz-container .math-options-list,
.math-quiz-container .math-option-label,
.math-quiz-container .math-button-container,
.math-quiz-summary,
.math-summary-item {
  text-align: left !important;
  justify-content: flex-start !important;
  align-items: flex-start !important;
}