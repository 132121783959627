/* src/components/blog/Post.module.css */

/* General Styling */
body, .post-wrapper {
  font-family: 'Arial', sans-serif;
  background-color: #f9f9f9;
  margin: 0;
  padding: 0;
}

.post-wrapper {
  display: flex;
  justify-content: center;
  padding: 0px;
  min-height: 100%;
  margin-top: 20px;
}

.post-main {
  background-color: #ffffff;
  border-radius: 15px;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.1);
  padding: 20px;
  width: 100%;
  max-width: 800px;
  overflow: hidden;
}

/* Title and Date Styling */
.post-title {
  margin-top: 0;
  margin-bottom: 10px;
  font-size: 2em;
  color: #2c3e50;
  /* Left-align the title */
  text-align: left;
}

.post-date {
  font-size: 1em;
  color: #777;
  margin-bottom: 20px;
  /* Left-align the date */
  text-align: left;
}

/* Content Styling */
.post-content {
  font-size: 1.1em;
  line-height: 1.6;
  color: #444;
}

/* Markdown Content Styling */
.post-content h1 {
  font-size: 1.5em; /* Slightly smaller than the main title */
  margin-top: 1.5em;
  margin-bottom: 0.5em;
  color: #2c3e50;
}

.post-content h2 {
  font-size: 1.75em;
  margin-top: 1.2em;
  margin-bottom: 0.5em;
  color: #2c3e50;
}

.post-content h3 {
  font-size: 1.5em;
  margin-top: 1em;
  margin-bottom: 0.5em;
  color: #2c3e50;
}

.post-content p {
  margin-bottom: 1em;
}

.post-content ul,
.post-content ol {
  margin-left: 1.5em;
  margin-bottom: 1em;
}

.post-content li {
  margin-bottom: 0.5em;
}

.post-content strong {
  font-weight: bold;
}

.post-content em {
  font-style: italic;
}

.post-content a {
  color: #2980b9;
  text-decoration: none;
}

.post-content a:hover {
  text-decoration: underline;
}

.post-content code {
  background-color: #f4f4f4;
  padding: 2px 4px;
  border-radius: 4px;
  font-family: 'Courier New', monospace;
  font-size: 0.95em;
}

.post-content pre {
  background-color: #f4f4f4;
  padding: 10px;
  overflow-x: auto;
  border-radius: 4px;
  font-family: 'Courier New', monospace;
  font-size: 0.95em;
}

/* Blockquotes */
.post-content blockquote {
  border-left: 4px solid #ddd;
  margin: 1.5em 10px;
  padding: 0.5em 10px;
  color: #555;
  background-color: #f9f9f9;
}

.post-content hr {
  border: 0;
  height: 1px;
  background: #ddd;
  margin: 2em 0;
}

/* Tables */
.post-content table {
  border-collapse: collapse;
  width: 100%;
  margin-bottom: 1em;
}

.post-content th,
.post-content td {
  border: 1px solid #ddd;
  padding: 8px;
}

.post-content th {
  background-color: #f2f2f2;
}

/* Images */
.post-content img {
  max-width: 100%;
  height: auto;
  display: block;
  margin: 1em 0;
}

/* Loading and Error Messages */
.loading,
.error {
  text-align: center;
  font-size: 1.2em;
  color: #555;
  margin-top: 50px;
}

/* Responsive Design */
@media (max-width: 768px) {
  .post-wrapper {
    padding: 20px;
  }

  .post-main {
    padding: 15px;
  }
}

@media (max-width: 480px) {
  .post-main {
    padding: 10px;
  }

  .post-title {
    font-size: 2em;
  }

  .post-content h1 {
    font-size: 1.75em;
  }

  .post-content h2 {
    font-size: 1.5em;
  }

  .post-content h3 {
    font-size: 1.25em;
  }
}