/* mathQuiz.css */

/* Override body styles */
body {
  display: block;
  justify-content: initial;
  align-items: initial;
  height: auto;
  padding: 20px 0;
}

/* Quiz container styles */
.satmath-quiz-container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  min-height: 400px;
  width: 100%;
  max-width: 800px;
  margin: 0 auto;
  padding: 20px;
  box-sizing: border-box;
  background-color: #fff;
  border-radius: 15px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
  margin-top: 20px;
  position: relative;
}

/* Tabs container */
.satmath-tabs {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-bottom: 20px;
  border-bottom: 2px solid #ccc;
  width: 100%;
}

/* Individual tab styles */
.satmath-tab {
  background-color: transparent;
  border: none;
  padding: 10px 20px;
  cursor: pointer;
  font-size: 18px;
  color: #555;
  margin-right: 10px;
  transition: color 0.3s ease, border-bottom 0.3s ease;
  border-bottom: 2px solid transparent;
}

.satmath-tab:hover {
  color: #007bff;
}

.satmath-tab.active {
  color: #007bff;
  border-bottom: 2px solid #007bff;
  font-weight: bold;
}

/* Welcome screen styles */
.satmath-welcome-screen {
  width: 100%;
}

.satmath-welcome-screen h1 {
  margin-bottom: 20px;
}

.satmath-welcome-screen button,
.satmath-quiz-container > button {
  font-size: 18px;
  padding: 10px 20px;
  cursor: pointer;
}

.satmath-welcome-header {
  text-align: center;
  margin-bottom: 20px;
}

.satmath-welcome-button-container,
.satmath-new-quiz-button-container {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 20px;
  width: 100%;
  flex-wrap: wrap;
}

.satmath-welcome-button,
.satmath-new-quiz-button,
.satmath-download-pdf-button {
  font-size: 18px;
  padding: 10px 20px;
  cursor: pointer;
  border: none;
  border-radius: 5px;
  transition: background-color 0.3s ease;
  margin: 10px;
  flex: 1;
  min-width: 200px;
  max-width: 300px;
}

.satmath-welcome-button,
.satmath-new-quiz-button {
  background-color: #007bff;
  color: white;
}

.satmath-welcome-button:hover,
.satmath-new-quiz-button:hover {
  background-color: #0056b3;
}

.satmath-download-pdf-button {
  background-color: #4CAF50;
  color: white;
}

.satmath-download-pdf-button:hover {
  background-color: #45a049;
}

.satmath-quiz-summary {
  margin-top: 20px;
  margin-bottom: 20px;
  border: 1px solid #ccc;
  padding: 10px;
  width: 100%;
  box-sizing: border-box;
  max-height: 400px;
  overflow-y: auto;
  padding-right: 20px;
}

.satmath-quiz-summary::-webkit-scrollbar {
  width: 10px;
}

.satmath-quiz-summary::-webkit-scrollbar-track {
  background: #f1f1f1;
}

.satmath-quiz-summary::-webkit-scrollbar-thumb {
  background: #888;
  border-radius: 5px;
}

.satmath-quiz-summary::-webkit-scrollbar-thumb:hover {
  background: #555;
}

.satmath-quiz-completion {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
}

.satmath-quiz-completion h2,
.satmath-quiz-completion h3 {
  text-align: center;
}

.satmath-question-instruction {
  font-style: italic;
  color: #666666;
  font-weight: bold;
}

.satmath-quiz-instructions {
  margin: 20px 0;
  text-align: left;
  max-width: 800px;
  margin-left: auto;
  margin-right: auto;
}

.satmath-quiz-instructions p {
  margin-bottom: 15px;
  line-height: 1.5;
}

.satmath-summary-item {
  margin-bottom: 15px;
  padding: 10px;
  border-radius: 5px;
  word-wrap: break-word;
  overflow-wrap: break-word;
}

.satmath-summary-item.correct {
  background-color: #d4edda;
}

.satmath-summary-item.incorrect {
  background-color: #f8d7da;
}

.satmath-summary-item p {
  margin-bottom: 5px;
}

.satmath-summary-item .satmath-question {
  font-weight: bold;
}

.satmath-summary-item .satmath-explanation {
  margin-top: 10px;
  padding: 10px;
  background-color: #f8f9fa;
  border-radius: 5px;
}

/* Heading styles */
h2,
h3 {
  color: #333;
  margin-bottom: 5px;
  text-align: left;
  width: 100%;
}

/* Question styles */
.satmath-question-text {
  font-size: 18px;
  margin-bottom: 0px;
  line-height: 1.6;
  text-align: left;
  width: 100%;
  word-wrap: break-word;
  overflow-wrap: break-word;
  hyphens: auto;
  padding: 10px;
}

/* MathML specific styles */
math {
  font-size: 16px;
  max-width: 100%;
  white-space: normal;
  word-break: break-word;
  overflow-wrap: break-word;
  display: inline-block;
  vertical-align: middle;
}

/* Style for individual math elements */
math * {
  max-width: 100%;
}

/* Ensure proper alignment of fractions */
mfrac {
  vertical-align: -0.5em;
}

/* Adjust spacing for operators and numbers */
mo,
mn {
  padding: 0 0.2em;
}

/* Options list styles */
.satmath-options-list {
  width: 100%;
  margin-top: 20px;
  margin-bottom: 0;
  padding: 0;
}

/* Styles for the option buttons */
.satmath-option-button {
  display: block;
  width: 100%;
  padding: 15px;
  margin-bottom: 10px;
  background-color: #f8f9fa;
  color: #333;
  border: 1px solid #ced4da;
  border-radius: 5px;
  text-align: left;
  cursor: pointer;
  font-size: 16px;
  transition: background-color 0.3s ease, border-color 0.3s ease;
  box-sizing: border-box;
}

.satmath-option-button:hover {
  background-color: #e9ecef;
  color: #333; /* Ensure text color remains readable on hover */
}

.satmath-option-button.correct:hover,
.satmath-option-button.incorrect:hover {
  background-color: inherit; /* Maintain the background color */
  color: #fff; /* Maintain white text color */
  cursor: not-allowed; /* Indicate that the button is not clickable */
}

.satmath-option-button.selected {
  background-color: #cce5ff;
  border-color: #b8daff;
  font-weight: bold;
}

.satmath-option-button.correct {
  background-color: #d4edda; /* Background for correct */
  border-color: #28a745; /* Border color for correct */
  color: #155724; /* Text color for correct */
}

.satmath-option-button.incorrect {
  background-color: #f8d7da; /* Background for incorrect */
  border-color: #dc3545; /* Border color for incorrect */
  color: #721c24; /* Text color for incorrect */
}

.satmath-option-button:disabled {
  cursor: not-allowed;
  opacity: 0.9; /* Slightly reduce opacity */
}

.satmath-option-text {
  display: inline-block;
  width: 100%;
  text-align: left;
  word-wrap: break-word;
  overflow-wrap: break-word;
}

/* Button container styles */
.satmath-button-container {
  margin-top: 10px;
  width: 100%;
  padding-bottom: 10px;
  text-align: left;
  display: flex;
  justify-content: flex-start;
}

/* Button styles */
button {
  background-color: #007bff;
  color: white;
  border: none;
  padding: 10px 20px;
  border-radius: 5px;
  cursor: pointer;
  font-size: 16px;
  transition: background-color 0.3s ease;
  margin-bottom: 0;
  width: auto;
}

button:hover {
  background-color: #0056b3;
}

button:disabled {
  background-color: #cccccc;
  cursor: not-allowed;
}

/* Explanation styles */
.satmath-explanation {
  margin-top: 20px;
  padding: 15px;
  background-color: #f8f9fa;
  border-radius: 5px;
  width: 100%;
  text-align: left;
  word-wrap: break-word;
  overflow-wrap: break-word;
}

.satmath-explanation .correct-answer {
  font-weight: bold;
  color: #28a745;
  margin-bottom: 10px;
}

.satmath-explanation-text {
  word-wrap: break-word;
  overflow-wrap: break-word;
  hyphens: auto;
}

/* Answer status styles */
.satmath-answer-status {
  font-weight: bold;
  margin-bottom: 10px;
}

.satmath-answer-status.correct {
  color: #28a745;
}

.satmath-answer-status.incorrect {
  color: #dc3545;
}

.satmath-saved-quiz-info {
  background-color: #f0f0f0;
  padding: 15px;
  border-radius: 5px;
  margin-bottom: 20px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  width: 100%;
  max-width: 800px;
  text-align: left;
}

.satmath-saved-quiz-info button {
  background-color: #4CAF50;
  font-weight: bold;
  color: white;
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 16px;
  margin-top: 10px;
  transition: background-color 0.3s ease;
  width: 100%;
}

.satmath-saved-quiz-info button:hover {
  background-color: #45a049;
}

.larger-inline-math {
  font-size: 1.6em;
}

/* Media query for mobile devices */
@media (max-width: 600px) {
  .satmath-options-list {
    /* Ensure options stack vertically on small screens */
    width: 100%;
  }

  .satmath-quiz-container {
    width: 100%;
    padding: 10px;
    margin-top: 20px;
  }

  button,
  .satmath-option-button {
    font-size: 14px;
    padding: 8px 16px;
  }

  .satmath-new-quiz-button,
  .satmath-download-pdf-button {
    font-size: 16px;
    padding: 8px 16px;
    min-width: 150px;
  }

  .satmath-saved-quiz-info button {
    font-size: 14px;
  }

  .satmath-quiz-summary {
    max-height: 300px;
  }

  .satmath-tab {
    font-size: 16px;
    padding: 8px 12px;
  }
}

/* Override specific App.css styles */
#satmath-quiz-container {
  width: 100%;
  max-width: 800px;
  margin: 0 auto;
  padding: 20px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

#satmath-options-list {
  /* Remove flex properties */
  width: 100%;
}

/* Ensure left alignment for all quiz elements */
.satmath-quiz-container > *,
.satmath-quiz-container .satmath-options-list,
.satmath-quiz-container .satmath-button-container,
.satmath-quiz-summary,
.satmath-summary-item {
  text-align: left !important;
  justify-content: flex-start !important;
  align-items: flex-start !important;
}