/* Home.css */

body, .home-wrapper {
  font-family: 'Arial', sans-serif;
  background-color: #f9f9f9;
  margin: 0;
  padding: 0;
}

.home-wrapper {
  display: flex;
  justify-content: center;
  padding: 0px;
  min-height: 100%;
  margin-top: 20px;
}

.home-main {
  background-color: #ffffff;
  border-radius: 15px;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.1);
  padding: 20px;
  width: 100%;
  max-width: 800px;
  overflow: hidden;
}

.home-header {
  margin-top: 0;
  padding-bottom: 0px;
  font-size: 1.5rem;
  color: #2c3e50;
  text-align: center;
}

.home-description {
  margin-top: 0;
  margin-bottom: 0px;
  padding-bottom: 20px;
  font-size: 0.95rem;
  color: #34495e;
  line-height: 1.6;
  text-align: left;
  max-width: 750px;
  margin-left: auto;
  margin-right: auto;
}

@import url('https://fonts.googleapis.com/css?family=Oswald:300,400,500,700');
@import url('https://fonts.googleapis.com/css?family=Open+Sans:300,400,600,700,800');

.gr-1 { background: linear-gradient(170deg, #007BFF 0%, #004BA0 100%); }
.gr-2 { background: linear-gradient(170deg, #28A745 0%, #1E7E34 100%); }
.gr-3 { background: linear-gradient(170deg, #6F42C1 0%, #443266 100%); }
.gr-4 { background: linear-gradient(170deg, #FFC107 0%, #D39E00 100%); }
.gr-5 { background: linear-gradient(170deg, #DC3545 0%, #BD2130 100%); }
.gr-6 { background: linear-gradient(170deg, #17A2B8 0%, #117A8B 100%); }

* {
  transition: .5s;
}

.column {
  margin-bottom: 1rem;
  padding-left: 0.5rem;
  padding-right: 0.5rem;
}

.column:hover {
  padding-left: 0;
}

.column:hover .card .txt {
  margin-left: 1rem;
}

.column:hover .card .txt h1,
.column:hover .card .txt p {
  color: rgba(255, 255, 255, 1);
  opacity: 1;
}

.column:hover a {
  color: rgba(255, 255, 255, 1);
}

.column:hover a:after {
  width: 10%;
}

.description-section {
  margin-bottom: 20px;
  min-height: 60px; /* Adjust as needed */
  padding: 10px;
  background-color: #f8f9fa;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0,0,0,0.1);
  transition: opacity 0.3s ease;

  display: flex;
  align-items: center;
  justify-content: center;
}

.description-section p {
  margin: 0;
  color: #333;
  font-size: 1rem;
  text-align: center;
}

.card-container {
  padding: 0 15px;
}

.card-link {
  text-decoration: none;
  display: block;
}

.card {
  max-width: 300px;
  margin: 0 auto;
  padding: 1.7rem 1.2rem;
  border: none;
  border-radius: 8px;
  color: rgba(0, 0, 0, 1);
  letter-spacing: 0.05rem;
  font-family: 'Oswald', sans-serif;
  box-shadow: 0 0 21px rgba(0, 0, 0, 0.27);
  transition: all 0.3s ease;
  cursor: pointer;
}

.card .card-body {
  padding: 0;
  position: relative;
}

.card .txt {
  margin-left: 0rem;
  z-index: 1;
}

.card .txt h1 {
  font-size: 1.2rem;
  font-weight: 300;
  text-transform: uppercase;
}

.ico-card {
  position: absolute;
  bottom: 10px;
  right: 10px;
  z-index: 0;
}

.ico-card svg {
  font-size: 4rem;
  opacity: 0.2;
  color: rgba(255, 255, 255, 1);
}

@media (max-width: 768px) {
  .home-wrapper {
    padding: 20px;
  }

  .home-main {
    padding: 15px;
  }
}

@media (max-width: 480px) {
  .home-main {
    padding: 10px;
  }
}