/* Calculator.css */
.calculator {
  width: 100%;
  max-width: 800px; /* Match the width of the math quiz container */
  margin: 20px auto;
  border: 1px solid #ccc;
  padding: 20px;
  border-radius: 15px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
  box-sizing: border-box;
  background-color: #fff; /* Ensure the background is white */
}

.calculator-display {
  font-size: 2em;
  text-align: right;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
  margin-bottom: 20px;
  background-color: #f9f9f9;
  width: 100%;
  box-sizing: border-box;
  color: #000; /* Ensure the display text is black */
}

.calculator-keypad {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 10px;
}

.function-keys button,
.digit-keys button,
.operator-keys button,
.advanced-functions button {
  font-size: 1.5em;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
  background-color: #f0f0f0; /* Light gray background for buttons */
  cursor: pointer;
  transition: background-color 0.3s ease;
  color: #000; /* Ensure the button text is black */
  display: flex;
  justify-content: center;
  align-items: center;
}

.function-keys button {
  background-color: #e0e0e0; /* Slightly darker gray for function keys */
}

.operator-keys button {
  background-color: #ffcc00; /* Yellow for operator keys */
}

.advanced-functions button {
  background-color: #d0d0d0; /* Slightly darker gray for advanced functions */
}

.function-keys button:hover,
.digit-keys button:hover,
.operator-keys button:hover,
.advanced-functions button:hover {
  background-color: #e9ecef;
}

/* Floating calculator icon */
.calculator-icon {
  font-size: 1.5em;
  padding: 10px;
  border: none; /* Remove border */
  background-color: transparent; /* Make background transparent */
  color: #000; /* Ensure the icon color is black */
  cursor: pointer;
  transition: color 0.3s ease;
  position: fixed;
  bottom: 20px;
  right: 20px;
  z-index: 1000; /* Ensure the tooltip is above other elements */
}

.calculator-icon:hover {
  color: #007bff; /* Change color on hover */
  background-color: transparent; /* Ensure no background color on hover */
}

/* Tooltip */
.calculator-icon .tooltip {
  visibility: hidden;
  width: 120px;
  background-color: #333;
  color: #fff;
  text-align: center;
  border-radius: 5px;
  padding: 5px 0;
  position: absolute;
  z-index: 1;
  bottom: 125%; /* Position the tooltip above the icon */
  left: 50%;
  margin-left: -60px; /* Center the tooltip */
  opacity: 0;
  transition: opacity 0.3s;
  font-size: 0.8em;
}

.calculator-icon:hover .tooltip {
  visibility: visible;
  opacity: 1;
}

.calculator-icon .tooltip::after {
  content: "";
  position: absolute;
  top: 100%;
  left: 50%;
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: #333 transparent transparent transparent;
}

/* Adjust grid layout for specific functions */
.function-keys {
  grid-column: span 4;
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 10px;
}

.digit-keys {
  grid-column: span 3;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 10px;
}

.digit-keys .backspace {
  grid-column: span 1;
}

.operator-keys {
  grid-column: span 1;
  display: grid;
  grid-template-rows: repeat(5, 1fr);
  gap: 10px;
}

.memory-functions {
  grid-column: span 4;
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 10px;
}

.trig-functions {
  grid-column: span 4;
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 10px;
}

.advanced-functions {
  grid-column: span 4;
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 10px;
}

/* Media query for smaller screens */
@media (max-width: 600px) {
  .calculator {
    padding: 10px;
  }

  .calculator-display {
    font-size: 1.5em;
  }

  .calculator-keypad {
    gap: 5px;
  }

  .function-keys button,
  .digit-keys button,
  .operator-keys button,
  .advanced-functions button {
    font-size: 1.2em;
    padding: 5px;
  }

  .calculator-icon {
    font-size: 1.2em;
    padding: 5px;
    position: fixed;
    top: 120px;
    right: 10px;
    bottom: auto;
  }

  /* Adjust tooltip for mobile screens */
  .calculator-icon .tooltip {
    width: 100px;
    margin-left: -50px;
    font-size: 0.7em;
    bottom: auto;
    top: 125%; /* Position the tooltip below the icon */
  }

  .calculator-icon .tooltip::after {
    top: auto;
    bottom: 100%;
    border-color: transparent transparent #333 transparent;
  }

  /* Ensure hover effect works on mobile */
  .calculator-icon:focus {
    color: #007bff; /* Change color on focus */
  }
}