/* AboutUs.css */

body, .about-us-wrapper {
  font-family: 'Arial', sans-serif;
  background-color: #f9f9f9;
  margin: 0;
  padding: 0;
}

.about-us-wrapper {
  display: flex;
  justify-content: center;
  padding: 0px;
  min-height: 100%;
  margin-top: 20px;
}

.about-us-main {
  background-color: #ffffff;
  border-radius: 15px;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.1);
  padding: 20px;
  width: 100%;
  max-width: 800px;
  overflow: hidden;
}

.about-us-header {
  margin-top: 0;
  padding-bottom: 20px;
  font-size: 1.5rem;
  color: #2c3e50;
  text-align: center;
}

.about-us-description {
  margin-top: 0;
  margin-bottom: 0;
  padding-bottom: 20px;
  font-size: 1.1rem;
  color: #34495e;
  line-height: 1.5;
}

@media (max-width: 768px) {
  .about-us-wrapper {
    padding: 20px;
  }

  .about-us-main {
    padding: 15px;
  }
}

@media (max-width: 480px) {
  .about-us-main {
    padding: 10px;
  }
}