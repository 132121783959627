.login-container {
  display: flex;
  align-items: baseline;
  justify-content: center;
  min-height: 100vh;
  background-color: #f9f9f9;
}

.login-box {
  background-color: white;
  padding: 2rem;
  border-radius: 1rem;
  box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06);
  width: 100%;
  max-width: 800px;
  text-align: center;
  margin-top: 20px;
}

.login-title {
  font-size: 1.875rem;
  font-weight: bold;
  margin-bottom: 1.5rem;
  color: #1f2937;
  text-align: center;
}

.login-benefits {
  list-style-type: none;
  padding: 0;
  margin-bottom: 20px;
  text-align: left;
}

.login-benefits li {
  margin-bottom: 10px;
  padding-left: 20px;
  position: relative;
}

.login-benefits li::before {
  content: "•";
  position: absolute;
  left: 0;
  color: #4285f4;
}

.login-button {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  padding: 0.5rem 1rem;
  font-size: 1rem;
  font-weight: 600;
  color: #4b5563;
  background-color: white;
  border: 1px solid #d1d5db;
  border-radius: 9999px;
  cursor: pointer;
  transition: all 0.3s ease;
}

.login-button:hover {
  background-color: #f0f0f0;
  box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06);
}

.login-button img {
  width: 1.5rem;
  height: 1.5rem;
  margin-right: 0.5rem;
}