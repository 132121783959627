/* ProfilePage.css */
.profile-form-wrapper {
  font-family: 'Arial', sans-serif;
  display: flex;
  justify-content: center;
  padding: 20px;
  min-height: 100%;
  flex-grow: 1;
  margin-top: 40px;
}

.profile-form-main {
  background-color: #ffffff;
  border-radius: 15px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
  padding: 20px;
  width: 100%;
  max-width: 800px;
}

.profile-form-main h1,
.profile-form-main h2,
.profile-form-main p {
  margin-top: 0;
  padding-bottom: 10px;
}

.profile-form-instruction {
  margin-bottom: 1rem;
  font-size: 1rem;
  color: #4a5568;
  text-align: left;
  font-weight: bold;
}

.profile-form-label {
  display: block;
  margin-bottom: 0.25rem;
  font-size: 0.875rem;
  font-weight: 500;
  color: #4a5568;
}

.profile-form-input {
  display: block;
  width: 100%;
  padding: 1rem;
  height: 2.5rem;
  border-radius: 0.375rem;
  border: 1px solid #d1d5db;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.1);
  transition: border-color 0.2s ease, box-shadow 0.2s ease;
}

.profile-form-main div {
  margin-bottom: 1.5rem;
}

.profile-form-input:focus {
  border-color: #4f46e5;
  box-shadow: 0 0 0 1px rgba(79, 70, 229, 0.5);
}

.profile-form-button {
  width: 100%;
  display: flex;
  justify-content: center;
  padding: 0.5rem 1rem;
  border: none;
  border-radius: 0.375rem;
  background-color: #007bff;
  color: white;
  font-size: 0.875rem;
  transition: background-color 0.3s ease;
  font-weight: bold;
}

.profile-form-button:hover {
  background-color: #0056B3;
}

.profile-form-success {
  margin-top: 1rem;
  padding: 1rem;
  background-color: #d1fae5;
  border: 1px solid #bbf7d0;
  color: #065f46;
  border-radius: 0.375rem;
}

.profile-form-error {
  margin-top: 1rem;
  padding: 1rem;
  background-color: #fee2e2;
  border: 1px solid #fca5a1;
  color: #b91c1c;
  border-radius: 0.375rem;
}

/* Media Queries for Mobile Optimization */
@media (max-width: 768px) {
  .profile-form-wrapper {
    padding: 10px;
  }

  .profile-form-main {
    padding: 15px;
  }
}

@media (max-width: 480px) {
  .profile-form-main {
    padding: 10px;
  }
}