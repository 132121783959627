/* ContactForm.css */

body, .contact-form-wrapper {
  font-family: 'Arial', sans-serif;
  background-color: #f9f9f9;
  margin: 0;
  padding: 0;
}

.contact-form-wrapper {
  display: flex;
  justify-content: center;
  padding: 0px;
  min-height: 100%;
  flex-grow: 1;
  margin-top: 20px;
}

.contact-form-main {
  background-color: #ffffff;
  border-radius: 15px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
  padding: 20px;
  width: 100%;
  max-width: 800px;
}

.contact-form-main h1,
.contact-form-main h2,
.contact-form-main p {
  margin-top: 0;
  padding-bottom: 10px;
}

.contact-form-header {
  text-align: left;
  margin-bottom: 20px;
  position: relative;
  z-index: 1; /* Ensure it's above other elements */
}

/* Align labels and inputs vertically */
.contact-form-main div {
  margin-bottom: 1.5rem; /* Space between each form group */
}

.contact-form-label {
  display: block; /* Ensure labels are block elements */
  margin-bottom: 0.25rem; /* Space between label and input */
  font-size: 0.875rem; /* text-sm */
  font-weight: 500; /* Medium weight for labels */
  color: #4a5568; /* Gray color for labels */
}

.contact-form-input {
  display: block;
  width: 100%; /* Full width for input fields */
  padding: 1rem; /* Increased padding for better touch targets */
  height: 2.5rem; /* Increased height for input fields */
  border-radius: 0.375rem; /* rounded-md */
  border: 1px solid #d1d5db; /* border-gray-300 */
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.1); /* shadow-sm */
  transition: border-color 0.2s ease, box-shadow 0.2s ease;
}

.contact-form-input:focus {
  border-color: #4f46e5; /* focus:border-indigo-300 */
  box-shadow: 0 0 0 1px rgba(79, 70, 229, 0.5); /* focus:ring */
}

/* Specific styles for the message textarea */
.contact-form-textarea {
  display: block; /* Ensure textarea is a block element */
  width: 100%; /* Full width for textarea */
  padding: 1rem; /* Increased padding for the textarea */
  min-height: 150px; /* Minimum height for the textarea */
  max-height: 300px; /* Maximum height for the textarea */
  resize: vertical; /* Allow vertical resizing */
  overflow-y: auto; /* Enable vertical scrolling */
  border-radius: 0.375rem; /* rounded-md */
  border: 1px solid #d1d5db; /* border-gray-300 */
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.1); /* shadow-sm */
  transition: border-color 0.2s ease, box-shadow 0.2s ease;
}

.contact-form-textarea:focus {
  border-color: #4f46e5; /* focus:border-indigo-300 */
  box-shadow: 0 0 0 1px rgba(79, 70, 229, 0.5); /* focus:ring */
}

.contact-form-button {
  width: 100%;
  display: flex;
  justify-content: center;
  padding: 0.5rem 1rem; /* py-2 px-4 */
  border: none;
  border-radius: 0.375rem; /* rounded-md */
  background-color: #007bff; /* bg-indigo-600 */
  color: white;
  font-size: 0.875rem; /* text-sm */
  transition: background-color 0.3s ease;
  font-weight: bold;
}

.contact-form-button:hover {
  background-color: #0056B3; /* hover:bg-indigo-700 */
  font-weight: bold;
}

.contact-form-success {
  margin-top: 1rem;
  padding: 1rem;
  background-color: #d1fae5; /* bg-green-100 */
  border: 1px solid #bbf7d0; /* border-green-400 */
  color: #065f46; /* text-green-700 */
  border-radius: 0.375rem; /* rounded */
}

.contact-form-instruction {
  margin-bottom: 1rem; /* Space below the instruction */
  font-size: 1rem; /* Font size for the instruction */
  color: #4a5568; /* Gray color for the instruction */
  text-align: left; /* Center the instruction text */
  font-weight: bold; /* Medium weight for better visibility */
}

.contact-form-error {
  margin-top: 1rem;
  padding: 1rem;
  background-color: #fee2e2; /* bg-red-100 */
  border: 1px solid #fca5a1; /* border-red-400 */
  color: #b91c1c; /* text-red-700 */
  border-radius: 0.375rem; /* rounded */
}

/* Media Queries for Mobile Optimization */
@media (max-width: 768px) {
  .contact-form-wrapper {
    padding: 10px;
  }

  .contact-form-main {
    padding: 15px;
  }
}

@media (max-width: 480px) {
  .contact-form-main {
    padding: 10px;
  }
}