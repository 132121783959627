/* src/components/blog/BlogList.css */

body, .blog-list-wrapper {
  font-family: 'Arial', sans-serif;
  background-color: #f9f9f9;
  margin: 0;
  padding: 0;
}

.blog-list-wrapper {
  display: flex;
  justify-content: center;
  padding: 0px;
  min-height: 100%;
  margin-top: 20px;
}

.blog-list-main {
  background-color: #ffffff;
  border-radius: 15px;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.1);
  padding: 20px;
  width: 100%;
  max-width: 800px;
  overflow: hidden;
}

.blog-list-header {
  margin-top: 0;
  padding-bottom: 20px;
  font-size: 2rem;
  color: #2c3e50;
  text-align: center;
}

.blog-list-item {
  margin-bottom: 20px;
}

.blog-list-item-title {
  font-size: 1.5rem;
  color: #34495e;
  margin-bottom: 5px;
}

.blog-list-item-date {
  font-size: 0.9rem;
  color: #777;
}

.blog-list-link {
  text-decoration: none;
  color: inherit;
}

.blog-list-link:hover {
  text-decoration: underline;
  color: #2980b9;
}

@media (max-width: 768px) {
  .blog-list-wrapper {
    padding: 20px;
  }

  .blog-list-main {
    padding: 15px;
  }
}

@media (max-width: 480px) {
  .blog-list-main {
    padding: 10px;
  }
}